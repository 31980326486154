import React, { Children } from 'react'

import PostSection from './PostSection'

const PostStanding = ({ children, title }) => {
  return (
    <PostSection title={title} section="standing">
      <div className="col-12 col-md-10">
        <div className="single-post-text table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Club</th>
                <th scope="col">Pts</th>
                <th scope="col">J</th>
                <th scope="col">G</th>
                <th scope="col">N</th>
                <th scope="col">P</th>
                <th scope="col">Bp</th>
                <th scope="col">Bc</th>
                <th scope="col">Diff</th>
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    </PostSection>
  )
}

export default PostStanding
