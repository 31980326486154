import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PostStanding from '../components/PostStanding'
import PostStandingRaw from '../components/PostStandingRaw'

export default ({ data, pageContext }) => {
  const { competition, standing } = data.footStanding
  const startDate = new Date(competition.season_start)
  const endDate = new Date(competition.season_end)
  const title = `Classement ${
    competition.name
  } ${startDate.getFullYear()}/${endDate.getFullYear()}`

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SEO />
      <PostStanding title={title}>
        {standing.map(team => (
          <PostStandingRaw {...team} />
        ))}
      </PostStanding>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    footStanding(id: { eq: $id }) {
      competition {
        name
        season
        season_end
        season_start
      }
      standing {
        points
        rank
        teamName
        logo
        all {
          matchsPlayed
          win
          lose
          goalsFor
          goalsAgainst
          draw
        }
        goalsDiff
      }
    }
  }
`
