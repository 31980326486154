import React from 'react'

import SocialIcons from './Common/SocialIcons'

import { getPostPath } from '../utils/helpers'

class PostSection extends React.Component {
  render() {
    const {
      frontmatter,
      children,
      category,
      title,
      publishDate = '',
      section = '',
    } = this.props
    const isCategory = typeof category !== 'undefined'
    let url = null
    if (frontmatter) url = getPostPath({ frontmatter })
    return (
      <section className="single-post-area" id={section}>
        <div className="single-post-title bg-dark">
          <div className="container h-100">
            <div className="row h-100 align-items-end">
              <div className="col-12">
                <div className="single-post-title-content">
                  {isCategory ? (
                    <div className="gazette-post-tag">
                      <a href="#">{category}</a>
                    </div>
                  ) : (
                    <div />
                  )}
                  <h2
                    className="font-pt"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  <div className="d-sm-flex align-items-center justify-content-between">
                    <p>{publishDate}</p>
                    {url && <SocialIcons {...frontmatter} url={url} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-post-contents">
          <div className="container">
            <div className="row justify-content-center">{children}</div>
          </div>
        </div>
      </section>
    )
  }
}

export default PostSection
