import React from 'react'

const PostStandingRaw = ({ rank, teamName, points, all, goalsDiff }) => {
  const { matchsPlayed, win, draw, lose, goalsFor, goalsAgainst } = all

  let trClass = ''
  switch (rank) {
    case 1:
    case 2:
    case 3:
    case 4:
      trClass = 'table-success'
      break
    case 18:
      trClass = 'table-warning'
      break
    case 19:
    case 20:
      trClass = 'table-danger'
      break
  }
  return (
    <tr className={trClass}>
      <th scope="row">{rank}</th>
      <td>{teamName}</td>
      <td>{points}</td>
      <td>{matchsPlayed}</td>
      <td>{win}</td>
      <td>{draw}</td>
      <td>{lose}</td>
      <td>{goalsFor}</td>
      <td>{goalsAgainst}</td>
      <td>{goalsDiff}</td>
    </tr>
  )
}

export default PostStandingRaw
